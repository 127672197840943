var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about"},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"pa-3",attrs:{"xs12":"","sm6":"","md4":"","lg3":""}},[_c('v-card',{staticClass:"card-top bgreen",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" About ")]),_c('v-card-subtitle',[_vm._v(" Tentang ")]),_c('v-card-text',[_vm._v(" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil magni ea eius adipisci. Quis nihil accusamus inventore fuga praesentium vitae possimus magnam ducimus. ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-actions',[_c('v-btn',_vm._g(_vm._b({attrs:{"block":""}},'v-btn',attrs,false),on),[_vm._v("TES")])],1)]}}])},[_c('span',[_vm._v("ini tooltip")])])],1)],1),_c('v-flex',{staticClass:"pa-3",attrs:{"xs12":"","sm6":"","md4":"","lg3":""}},[_c('v-card',{staticClass:"card-top bred",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" About ")]),_c('v-card-subtitle',[_vm._v(" Tentang ")]),_c('v-card-text',[_vm._v(" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil magni ea eius adipisci. Quis nihil accusamus inventore fuga praesentium vitae possimus magnam ducimus. ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-actions',[_c('v-btn',_vm._g(_vm._b({attrs:{"block":""}},'v-btn',attrs,false),on),[_vm._v("TES")])],1)]}}])},[_c('span',[_vm._v("ini tooltip")])])],1)],1),_c('v-flex',{staticClass:"pa-3",attrs:{"xs12":"","sm6":"","md4":"","lg3":""}},[_c('v-card',{staticClass:"card-top bblue",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" About ")]),_c('v-card-subtitle',[_vm._v(" Tentang ")]),_c('v-card-text',[_vm._v(" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil magni ea eius adipisci. Quis nihil accusamus inventore fuga praesentium vitae possimus magnam ducimus. ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-actions',[_c('v-btn',_vm._g(_vm._b({attrs:{"block":""}},'v-btn',attrs,false),on),[_vm._v("TES")])],1)]}}])},[_c('span',[_vm._v("ini tooltip")])])],1)],1),_c('v-flex',{staticClass:"pa-3",attrs:{"xs12":"","sm6":"","md4":"","lg3":""}},[_c('v-card',{staticClass:"card-top bpurple",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" About ")]),_c('v-card-subtitle',[_vm._v(" Tentang ")]),_c('v-card-text',[_vm._v(" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil magni ea eius adipisci. Quis nihil accusamus inventore fuga praesentium vitae possimus magnam ducimus. ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-actions',[_c('v-btn',_vm._g(_vm._b({attrs:{"block":""}},'v-btn',attrs,false),on),[_vm._v("TES")])],1)]}}])},[_c('span',[_vm._v("ini tooltip")])])],1)],1)],1),_c('v-layout',{staticClass:"pa-3",attrs:{"row":""}},[_c('v-flex',[_c('v-card',{staticClass:"card-left bpurple",attrs:{"tile":"","flat":""}},[_c('v-card-title',[_vm._v("Hellow")]),_c('v-card-text',[_vm._v("Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque expedita ducimus totam ipsum dolore inventore voluptatum consequuntur adipisci eum dicta soluta pariatur amet doloremque numquam, excepturi quisquam architecto maiores sunt?")])],1)],1),_c('v-flex',[_c('v-card',{staticClass:"card-left bred",attrs:{"tile":"","flat":""}},[_c('v-card-title',[_vm._v("Hellow")]),_c('v-card-text',[_vm._v("Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque expedita ducimus totam ipsum dolore inventore voluptatum consequuntur adipisci eum dicta soluta pariatur amet doloremque numquam, excepturi quisquam architecto maiores sunt?")])],1)],1),_c('v-flex',[_c('v-card',{staticClass:"card-left bgreen",attrs:{"tile":"","flat":""}},[_c('v-card-title',[_vm._v("Hellow")]),_c('v-card-text',[_vm._v("Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque expedita ducimus totam ipsum dolore inventore voluptatum consequuntur adipisci eum dicta soluta pariatur amet doloremque numquam, excepturi quisquam architecto maiores sunt?")])],1)],1),_c('v-flex',[_c('v-card',{staticClass:"card-left bblue",attrs:{"tile":"","flat":""}},[_c('v-card-title',[_vm._v("Hellow")]),_c('v-card-text',[_vm._v("Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque expedita ducimus totam ipsum dolore inventore voluptatum consequuntur adipisci eum dicta soluta pariatur amet doloremque numquam, excepturi quisquam architecto maiores sunt?")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }